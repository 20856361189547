var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        _vm._b(
          {
            directives: [
              { name: "dialogDrag", rawName: "v-dialogDrag" },
              {
                name: "loading",
                rawName: "v-loading",
                value: !_vm.dataObj,
                expression: "!dataObj",
              },
            ],
            class: [
              "view-dialog",
              _vm.dialog.hideTitle ? "hideTitle" : "",
              _vm.dialog.type === "videoPalyer" ? "hide-body" : "",
              _vm.dialog.type,
            ],
            style: _vm.cssVar,
            attrs: {
              visible: _vm.dialog.show,
              title: _vm.dialog.title,
              customClass: "customClass",
              width: String(_vm.dialog.width || "30%"),
              "show-close": !_vm.dialog.hideTitle,
              "close-on-click-modal": Boolean(_vm.dialog.otherClose),
              "close-on-press-escape": Boolean(_vm.dialog.otherClose),
              "before-close": _vm.handleClose,
              "append-to-body": "",
              top: "0vh",
            },
            on: {
              "update:visible": function ($event) {
                return _vm.$set(_vm.dialog, "show", $event)
              },
              open: function ($event) {
                return _vm.change(true)
              },
              closed: function ($event) {
                return _vm.change(false)
              },
            },
          },
          "el-dialog",
          _vm.$attrs,
          false
        ),
        [
          !_vm.dataObj
            ? _c("div", { staticStyle: { height: "250px" } })
            : _vm._e(),
          _vm.showContent && _vm.dataObj
            ? [
                _c(_vm.dialog.type, {
                  ref: "myDialog",
                  tag: "component",
                  attrs: {
                    keys: String(new Date()),
                    dialog: _vm.dialog,
                    dataObj: _vm.dataObj,
                  },
                  on: {
                    "update:dialog": function ($event) {
                      _vm.dialog = $event
                    },
                    dialogEvent: _vm.dialogEvent,
                  },
                  model: {
                    value: _vm.dialog.formData,
                    callback: function ($$v) {
                      _vm.$set(_vm.dialog, "formData", $$v)
                    },
                    expression: "dialog.formData",
                  },
                }),
              ]
            : _vm._e(),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }